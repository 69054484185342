import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { customStateMethods } from '../../protected/CustomAppState/CustomState';
import './DoctorsRegistration.css';
import userIcon from '../../../Assets/img/registration/userIcon.jpeg';

export const DocCredentials = () => {
    const [loading, setLoading] = useState(null);
    const [messages, setMessages] = useState(null);

    const [docData, setDocData] = useState(null);
    
    customStateMethods.useClearAlert(setMessages);
    
    const {id} = useParams();

    useEffect(()=>{
        try{

            setLoading(customStateMethods.spinnerDiv(true));

            axios.get('sanctum/csrf-cookie').then(response => {
                axios.get(`/api/admin/fetch-doctorCred/${id}`)
                  .then((res) => {
                       
                       if(res.data.status === 200){
                        setDocData(res.data.doc_data);
                        setMessages(customStateMethods.getAlertDiv(res.data.message))
                       }else{
                        setMessages(customStateMethods.getAlertDiv(res.data.message))
                        setLoading(false);
                       }
                       setLoading(false);
                  })
            });
        }catch(error){
            console.log(error);
        }

    },[])

  return (
    <div>DocCredentials</div>
  )
}
