import {Routes, Route} from 'react-router-dom';
import { Home } from './Admin/Layouts/Home';
import { Profile } from './Admin/Layouts/Profile';
import { Test } from './Admin/Layouts/test';
import { Sync } from './Admin/Layouts/sync';
import { Login } from './Authentication/login';
import { AddTeam } from './Admin/Components/AddTeam';
import { LoginRouteProtected } from './Admin/protected/loginRouteProtected';
import { VerifyEmail } from './Admin/protected/VerifyEmail';
import { Register } from './Authentication/register';
import { RegistrationTest } from './Test/RegistrationTest';
import { AddDoctors } from './Admin/Components/DoctorRegistration/AddDoctors';
import { ViewDoctors } from './Admin/Components/DoctorRegistration/ViewDoctors';
import { FullInformation } from './Admin/Components/DoctorRegistration/FullInformation';
import { DocCredentials } from './Admin/Components/DoctorRegistration/DocCredentials';



function App() {

  return (
    <div className="App">
      
      <Routes>
        <Route path="/" element={<VerifyEmail/>} />
        <Route path="login" element={<LoginRouteProtected>{<Login />}</LoginRouteProtected>} />

        <Route path='testOne' element={<LoginRouteProtected/>} />


        <Route path="admin" element={<Home/>}>
          <Route path="profile" element={<Profile/>} />
          <Route path="test" element={<Test/>} />
          <Route path="sync" element={<Sync/>} />

          {/* dashboard components routes starts from here  */}
          <Route path='add-team' element={<AddTeam/>} />
          <Route path='add-doctors' element={<AddDoctors/>}/>
          <Route path='view-doctors' element={<ViewDoctors/>}/>
          <Route path='full-information/:id' element={<FullInformation/>}/>
          <Route path='doc-credentials/:id' element={<DocCredentials/>}/>


        </Route>

        <Route>
          <Route path='/sign-up' element={<Register/>}/>
          <Route path='/test-reg' element={<RegistrationTest/>}/>
        </Route>

      </Routes>




    </div>
  );
}

export default App;
