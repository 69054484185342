import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoginRouteProtected = ({ children }) => {
    const navigate = useNavigate();
    const [isVerified, setIsVerified] = useState(false);
    

    useEffect(() => {
        try {

            let appState = JSON.parse(localStorage.getItem('appState'));

            if (appState !== null && appState.email_status == null) {
                console.log('working');
                setIsVerified(true);
            } else {
                navigate('/');
                console.log('email_status is not found or appState is invalid.');
            }
           
         
        } catch (error) {
            console.log(error);
        }
    }, []); 


    return (
        <div>
            {isVerified ? children : null} {/* Rendering children only if verified */}
        </div>
    );
};
